.model-list-wrapper {
    padding: 0.5rem;
    padding-top: 1rem;
}

.model-card {
    width: 16rem; 
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;

}

.model-card-image {
    height: 16rem; 
    width: 16rem; 

}